<template>
  <div class="admin-game-add">
    <h5>增加批次<router-link class="btn btn-outline-secondary btn-sm float-right" :to="{path:'/admin/game'}">返回列表</router-link></h5>


    <div class="main mt-3">
      <form action="" @submit="onSubmit()">
        <div class="row">
          <div class="col-6">
            <div class="form-group row">
              <label for="inputTitle" class="col-sm-2 col-form-label">批次标题</label>
              <div class="col-sm-10">
                <input type="text" name="title" id="inputTitle" class="form-control">
              </div>
            </div>
            <div class="form-group row">
              <label for="inputCode" class="col-sm-2 col-form-label">批次编码</label>
              <div class="col-sm-10">
                <input type="text" name="code" id="inputCode" class="form-control">
              </div>
            </div>
            <div class="form-group row">
              <label  class="col-sm-2 col-form-label">图片</label>
              <div class="col-sm-10">
                <input type="file" name="image" class="form-control">
              </div>
            </div>
            <div class="form-group row">
              <label for="inputTitle" class="col-sm-2 col-form-label">备注</label>
              <div class="col-sm-10">
                <input type="text" name="title" id="inputTitle" class="form-control">
              </div>
            </div>
            <div class="form-group row">
              <div class="col-auto">
                <button type="button" class="btn btn-primary">保存</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {

    }
  },
  mounted() {
  },
  methods: {
    onSubmit(e) {
      e.preventDefault();
    }
  }
}
</script>